import Link from 'next/link';
import { memo } from 'react';
import styles from '../../styles/components/common/Breadcrumb.module.scss';

const Breadcrumb = memo(({ items, type }) => {
    return (
        <div className={`${styles.container} ${type}`}>
            <ul className={styles.list}>
                {items.map((data) => {
                    return (
                        <li className={styles.item} key={data.key}>
                            <Link href={data.href}>
                                <a
                                    className={`${styles.link} js-hover breadcrumbLink`}
                                >
                                    {data.text}
                                </a>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

Breadcrumb.displayName = 'Breadcrumb';

export default Breadcrumb;
