import { memo } from 'react';
import Img from '../common/Img';
import MainButton from '../../button/MainButton';
import styles from '../../../styles/components/section/app/Start.module.scss';

const Start = memo(() => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.img}>
                    <span className={styles.imgText}>WEB APP</span>
                    <Img
                        src="/image/app/start.jpg"
                        spSrc="/image/app/sp/start.jpg"
                        webpSrc="/image/app/start.webp"
                        webpSpSrc="/image/app/sp/start.webp"
                        alt="WEB APP"
                    />
                </div>
                <div className={styles.content}>
                    <p className={styles.contentText}>
                        iPhone / Android
                        APPだけではなく、WEBサイトからオーダーできるWEB
                        APPも開発してます。
                        <br />
                        会員登録するだけで好きなサラダにカスタマイズ、近くのCRISP
                        SALAD WORKSへオーダーできるから、すごく便利。CRISP
                        APPをインストールしたくない人、こっちを使うのもオススメ！
                    </p>
                    <MainButton
                        text="START WEB ORDER"
                        href="https://crisp.pltfrm.jp"
                        out={true}
                    />
                </div>
            </div>
        </div>
    );
});

Start.displayName = 'Start';

export default Start;
