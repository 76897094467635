import { memo } from 'react';
import useHover from '../../../hooks/useHover';
import useUserAgent from '../../../hooks/useUserAgent';
import useWindowWidth from '../../../hooks/useWindowWidth';
import Img from '../common/Img';
import styles from '../../../styles/components/section/app/Kv.module.scss';

const Kv = memo(() => {
    let bodyFeatureItem = [
        {
            key: 1,
            text: 'お店に行かずにじっくりサラダをカスタムしてモバイルオーダー！',
        },
        {
            key: 2,
            text: '事前にオーダーできるので、あとは指定の時間にお店にピックアップにいくだけ',
        },
        {
            key: 3,
            text: 'クレジットカードを登録してカンタンに決済',
        },
        {
            key: 4,
            text: '複雑にカスタマイズしたサラダだってオーダー履歴にちゃんと残る',
        },
        {
            key: 5,
            text: 'キャンペーンなど、最新のCRISP情報がすぐわかる',
        },
        {
            key: 6,
            text: 'リワードによって特殊なプロモーションがもらえることも',
        },
        {
            key: 7,
            text: 'インストールしておけばなんとなくイケてる気がする',
        },
    ];

    useHover();
    const { breakPoint, windowWidth } = useWindowWidth();
    const { mobileType } = useUserAgent();

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.img}>
                    <span className={styles.imgText}>CRISP APP</span>
                    <Img
                        src="/image/app/kv.jpg"
                        spSrc="/image/app/sp/kv.jpg"
                        webpSrc="/image/app/kv.webp"
                        webpSpSrc="/image/app/sp/kv.webp"
                        loading="eager"
                        alt="CRISP APP"
                    />
                </div>
                <div className={styles.content}>
                    <div className={styles.head}>
                        <h1 className={styles.headTitle}>
                            HELLO!
                            <br />
                            CRISP APP
                        </h1>
                    </div>
                    <div className={styles.body}>
                        <p className={styles.bodyText}>
                            <span className={styles.bodyTextInside}>
                                CRISPの最新情報から、エキサイティングなサラダ体験まで。
                            </span>
                            <span className={styles.bodyTextInside}>
                                クレジットカード登録によるカンタンオーダー＆スムーズピックアップ、二度と思い出せないような複雑なカスタマイズもちゃんと注文履歴に残るからいつでも再オーダーが可能。会社で注文して届けてもらうなんてことも。そう、CRISP
                                APPはあなたのサラダ体験をエキサイティングに変えるんです。
                            </span>
                            <span className={styles.bodyTextInside}>
                                しかも、知ってましたか？CRISP
                                APPは完全自社開発。あなたがこれを読んでいる今まさにこの時間にも
                                <a
                                    className={`${styles.bodyTextInsideLink} js-hover js-hover__color__point`}
                                    data-hover-in-time="400"
                                    data-hover-out-time="0"
                                    href="https://corp.crisp.co.jp/careers/farm/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    イケてるCRISPのクリエイター達
                                </a>
                                によってAPPは進化し続けているのです。
                                <br />
                                …たまに不具合を起こすけど、それは本当にごめんなさい。。
                            </span>
                        </p>
                        <div className={styles.bodyIntro}>
                            {windowWidth > breakPoint ? (
                                <div
                                    className={`${styles.bodyIntroImgPc} u-none__sp`}
                                >
                                    <Img
                                        src="/image/app/kv_qr.png"
                                        webpSrc="/image/app/kv_qr.webp"
                                        alt="CRISP APP"
                                    />
                                </div>
                            ) : (
                                <>
                                    {mobileType === 'mobileApple' && (
                                        <div
                                            className={`${styles.bodyIntroImgSpIos} u-none__pc`}
                                        >
                                            <a
                                                className="bodyIntroImgSpIosLink"
                                                href="https://apps.apple.com/us/app/id1526776438"
                                            >
                                                <Img
                                                    src="/image/app/sp/kv_ios.jpg"
                                                    webpSrc="/image/app/sp/kv_ios.webp"
                                                    alt="CRISP APP"
                                                />
                                            </a>
                                        </div>
                                    )}
                                    {mobileType === 'mobileAndroid' && (
                                        <div
                                            className={`${styles.bodyIntroImgSpAndroid} u-none__pc`}
                                        >
                                            <a
                                                className="bodyIntroImgSpAndroidLink"
                                                href="https://play.google.com/store/apps/details?id=jp.crisp.pltfrm.crisp"
                                            >
                                                <Img
                                                    src="/image/app/sp/kv_android.jpg"
                                                    webpSrc="/image/app/sp/kv_android.webp"
                                                    alt="CRISP APP"
                                                />
                                            </a>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className={styles.bodyFeature}>
                            <h2 className={styles.bodyFeatureTitle}>
                                iOS / Android
                            </h2>
                            <ul className={styles.bodyFeatureList}>
                                {bodyFeatureItem.map((data) => {
                                    return (
                                        <li
                                            className={styles.bodyFeatureItem}
                                            key={data.key}
                                        >
                                            {data.text}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

Kv.displayName = 'Kv';

export default Kv;
