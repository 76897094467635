import Link from 'next/link';
import { memo } from 'react';
import styles from '../../styles/components/button/MainButton.module.scss';

const MainButton = memo(({ text, href, out }) => {
    return (
        <>
            {href ? (
                out ? (
                    <a
                        className={`${styles.container} js-hover js-hover__btn__key`}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className={`${styles.text} js-hover__btn-text`}>
                            {text}
                        </span>
                    </a>
                ) : (
                    <Link href={href}>
                        <a
                            className={`${styles.container} js-hover js-hover__btn__key`}
                        >
                            <span
                                className={`${styles.text} js-hover__btn-text`}
                            >
                                {text}
                            </span>
                        </a>
                    </Link>
                )
            ) : (
                <div
                    className={`${styles.container} js-hover js-hover__btn__key`}
                >
                    <span className={`${styles.text} js-hover__btn-text`}>
                        {text}
                    </span>
                </div>
            )}
        </>
    );
});

MainButton.displayName = 'MainButton';

export default MainButton;
